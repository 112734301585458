import React, { Component } from "react";
import { connect } from "react-redux";
import { ConfigProvider, Layout } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import AppRouter from "./AppRouter";
import { siteConfig } from "../../picsureAppConfig.js";
import { AppLocale } from "../../index";
import themes from "../../theme/themes";
import AppHolder from "./commonStyle";
import "./global.css";
import ReactGA from "react-ga4";
import "./tailwind.css"

import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/antd.less";


const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
  componentWillMount() {
    ReactGA.pageview("dashboard");
  }

  render() {
    const { url } = this.props.match;
    const { locale, selectedTheme } = this.props;
    const currentAppLocale = AppLocale[locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: "100vh" }}>
                <Debounce time="1000" handler="onResize">
                  <WindowSizeListener onResize={(windowSize) => this.props.toggleAll(windowSize.windowWidth, windowSize.windowHeight)} />
                </Debounce>
                <Topbar url={url} />
                <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
                  <Sidebar url={url} />
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: "100vh",
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: "70px 0 0",
                        flexShrink: "0",
                        background: "#f1f3f6",
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>
                    <Footer
                      style={{
                        background: "#ffffff",
                        textAlign: "center",
                        borderTop: "1px solid #ededed",
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.toJS().language.locale,
    selectedTheme: state.ThemeSwitcher.toJS().changeThemes.themeName,
    user: state.Auth.user,
  }),
  { logout, toggleAll }
)(App);
