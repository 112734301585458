import picsureIcon from "./assets/images/logo/picsure_logo.svg";

const siteConfig = {
  siteName: "Picsure - Buildings management webapp",
  siteIconBig: picsureIcon,
  siteIcon: picsureIcon,
  footerText: "Picsure © " + new Date().getFullYear(),
  apiUrl: "https://api.picsure.ai"
};

const themeConfig = {
  topbar: "themedefault",
  sidebar: "themePicsure",
  layout: "themedefault",
  theme: "themedefault"
};
const language = "english";

export { siteConfig, themeConfig, language };
