const authActions = {
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	REAUTHENTICATE_REQUEST: 'REAUTHENTICATE_REQUEST',
	REAUTHENTICATE_SUCCESS: 'REAUTHENTICATE_SUCCESS',
	REAUTHENTICATE_ERROR: 'REAUTHENTICATE_ERROR',
	GETUSER_REQUEST: 'GETUSER_REQUEST',
	GETUSER_SUCCESS: 'GETUSER_SUCCESS',
	GETUSER_ERROR: 'GETUSER_ERROR',
	SAVEUSER_REQUEST: 'SAVEUSER_REQUEST',
	SAVEUSER_SUCCESS: 'SAVEUSER_SUCCESS',
	SAVEUSER_ERROR: 'SAVEUSER_ERROR',
	LOGOUT: 'LOGOUT',
	CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',

	login: (username, password) => ({
		type: authActions.LOGIN_REQUEST,
		username: username,
		password: password
	}),
	logout: () => ({
		type: authActions.LOGOUT
	}),
	clearNotifications: () => ({
		type: authActions.CLEAR_NOTIFICATIONS
	}),
	reauthenticate: (from = null) => ({
		type: authActions.REAUTHENTICATE_REQUEST,
		from: from
	}),
	saveUser: (id, data) => ({
		type: authActions.SAVEUSER_REQUEST,
		id: id,
		data: data
	})
};
export default authActions;
