import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import _ from "lodash";
import Logo from "../../components/utility/logo";
import { rtl } from "../../theme/withDirection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faSlidersH, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import appActions from "../../redux/app/actions";

const { Sider } = Layout;
const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } = appActions;
const DEV = process.env.NODE_ENV !== 'production';
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  componentWillMount() {
    DEV && console.log("get schools");
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find((key) => !(app.openKeys.indexOf(key) > -1));
    const latestCloseKey = app.openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  renderView({ style, ...props }) {
    const viewStyle = {
      marginRight: rtl === "rtl" ? "0" : "-17px",
      paddingRight: rtl === "rtl" ? "0" : "9px",
      marginLeft: rtl === "rtl" ? "-17px" : "0",
      paddingLeft: rtl === "rtl" ? "9px" : "0",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  }

  render() {
    const { url, app, toggleOpenDrawer, customizedTheme } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const scrollheight = app.height;
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };

    const menuColor = {
      color: customizedTheme.textColor,
    };
    const scrollBarStyling = {
      height: scrollheight - 70,
      boxShadow: "0 23px 40px rgba(0, 0, 0, 0.2)",
    };

    const onMouseEnter = (event) => {
      const {collapsed} = this.props;
      if (openDrawer === false && collapsed) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      const {collapsed} = this.props;
      if (openDrawer === true && collapsed) {
        toggleOpenDrawer();
      }
      return;
    };


    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width="240"
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars renderView={this.renderView} style={scrollBarStyling}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
              className="isoDashboardMenu"
            >
              <Menu.Item key="dashboard">
                <Link to={`${url}/dashboard`}>
                  <span className="isoMenuHolder" style={menuColor}>
                  <FontAwesomeIcon icon={faGlobeAmericas} />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.map" />
                    </span>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="assets">
                <Link to={`${url}/assets`}>
                  <span className="isoMenuHolder" style={menuColor}>
                  <FontAwesomeIcon icon={faSlidersH} />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.portfolio" />
                    </span>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item disabled={true} key="claims_management">
                  <span className="isoMenuHolder" style={menuColor}>
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.claims_management" />
                    </span>
                  </span>
              </Menu.Item>
              {/* <Menu.Item key="cockpit">
                <Link to={`${url}/cockpit`}>
                  <span className="isoMenuHolder" style={menuColor}>
                    <i className="ion-ios-speedometer" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.cockpit" />
                    </span>
                  </span>
                </Link>
              </Menu.Item> */}
           
           
            
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App.toJS(),
    collapsed: state.App.collapsed,
    current: state.App.current,
    customizedTheme: state.ThemeSwitcher.toJS().sidebarTheme,
  }),
  {  toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
