import actions from './actions';

const initState = (token => ({
	isAuthenticating: false,
	user: null,
	error: false,
	errorMessage: null,
	isLoggedIn: false,
	success: false
}))(localStorage.access_token);

export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_REQUEST:
			return {
				...state,
				isAuthenticating: true,
				error: false,
				errorMessage: null
			};
		case actions.LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticating: false,
				error: false,
				errorMessage: null,
				isLoggedIn: true
			};
		case actions.REAUTHENTICATE_SUCCESS:
			return {
				...state,
				isAuthenticating: false,
				user: action.user,
				error: false,
				message: null,
				errorMessage: null,
				isLoggedIn: true
			};
		
		case actions.LOGOUT:
			//delete token
			delete localStorage.access_token;
			delete localStorage.refresh_token;
			return {
				...state,
				isAuthenticating: false,
				user: null,
				error: false,
				errorMessage: null,
				isLoggedIn: false
			};
		case actions.LOGIN_ERROR:
			return {
				...state,
				isAuthenticating: false,
				user: null,
				error: true,
				errorMessage: action.errorMessage,
				isLoggedIn: false
			};

		case actions.SAVEUSER_SUCCESS:
			const user = {
				...state.user,
				address_1: action.user.address_1,
				address_2: action.user.address_2,
				country: action.user.country,
				city: action.user.city,
				zip_code: action.user.zip_code,
				phone_number: action.user.phone_number,
				fax_number: action.user.fax_number,
				send_email_stats_projects: action.user.send_email_stats_projects,
				send_email_status_projects: action.user.send_email_status_projects
			};

			return {
				...state,
				error: false,
				success: true,
				message: action.message,
				user: user
			};
		case actions.SAVEUSER_ERROR:
			return {
				...state,
				error: true,
				success: false,
				message: action.message
			};
		case actions.GETUSER_ERROR:
			return {
				...state,
				isAuthenticating: false,
				user: null,
				error: false,
				errorMessage: null,
				isLoggedIn: false
			};
		case actions.GETUSER_SUCCESS:
			return {
				...state,
				isAuthenticating: false,
				user: action.user,
				error: false,
				errorMessage: null,
				isLoggedIn: true
			};
		case actions.CLEAR_NOTIFICATIONS:
			return {
				...state,
				error: false,
				success: false,
				message: false,
				errorMessage: null
			};

		default:
			return state;
	}
}
