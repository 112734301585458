import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../picsureAppConfig.js';

// export default function({ collapsed, styling }) {
const Logo = ({ collapsed })  => {
  const style = {
    height: '50px',
    verticalAlign: 'middle',
    outline: 'none',
    marginTop: "10px"
  };
  return (
    <div
      className="isoLogoWrapper">
      {collapsed
        ? <div>
            <h3>
              <Link to="/app/dashboard">
                  <img src={process.env.PUBLIC_URL + siteConfig.siteIcon} alt="Picsure" style={style}/>
              </Link>
            </h3>
          </div>
        : <h3>
            <Link to="/app/dashboard">
              <img src={process.env.PUBLIC_URL + siteConfig.siteIconBig} alt="Picsure" style={style} />
            </Link>
          </h3>}
    </div>
  );
}

export default Logo;
