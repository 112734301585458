import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Popover } from 'antd';

import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';

const { logout } = authAction;

//TranslationObjects
const langProfile = {
	en: 'Profile',
	de: 'Profil'
};

const langDocumentation = {
	en: 'Documentation',
	de: 'Dokumentation'
};

const langLogout = {
	en: 'Logout',
	de: 'Abmelden'
};

class TopbarUser extends Component {
	constructor(props) {
		super(props);
		const lang = this.props.LanguageSwitcher.language.locale ? this.props.LanguageSwitcher.language.locale : 'en';
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.hide = this.hide.bind(this);
		this.state = {
			visible: false,
			lang: lang,
			langProfile: langProfile[lang],
			langDocumentation: langDocumentation[lang],
			langLogout: langLogout[lang]
		};
	}

	hide() {
		this.setState({ visible: false });
	}
	handleVisibleChange() {
		this.setState({ visible: !this.state.visible });
	}

	hideHandle = () => {
		this.hide();
		this.props.logout()
	};

	componentWillReceiveProps(nextProps) {
		const newLang = nextProps.LanguageSwitcher.language.locale;
		if (newLang !== this.state.lang) {
			this.setState({
				lang: newLang,
				langProfile: langProfile[newLang],
				langDocumentation: langDocumentation[newLang],
				langLogout: langLogout[newLang]
			});
		}
	}

	render() {
		// const { url, app, toggleOpenDrawer, bgcolor } = this.props;

		const imageWrapperStyle = {
			background: '#F1F3F6'
		};
		const content = (
			<TopbarDropdownWrapper className="isoUserDropdown">
				{/* <Link onClick={this.hideHandle} className="isoDropdownLink" to={`${url}/profile`}>
					{this.state.langProfile}
				</Link>
				<Link onClick={this.hideHandle} className="isoDropdownLink" to={`${url}/documentation`}>
					{this.state.langDocumentation}
				</Link> */}
				<a  href onClick={this.hideHandle} className="isoDropdownLink">
					{this.state.langLogout}
				</a>
			</TopbarDropdownWrapper>
		);

		return (
			<Popover content={content} trigger="click" visible={this.state.visible} onVisibleChange={this.handleVisibleChange} arrowPointAtCenter={true} placement="bottomLeft">
				<div className="isoImgWrapper" style={imageWrapperStyle}>
					<i className="ion-person" />
				</div>
			</Popover>
		);
	}
}

export default connect(
	state => ({
		app: state.App.toJS(),
		LanguageSwitcher: state.LanguageSwitcher.toJS(),
		customizedTheme: state.ThemeSwitcher.toJS().sidebarTheme
	}),
	{ logout }
)(TopbarUser);
