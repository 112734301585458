import Auth from './auth/reducer';
import App from './app/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import Asset from './asset/reducer';

export default {
	Auth,
	App,
	ThemeSwitcher,
	LanguageSwitcher,
	Asset
};
