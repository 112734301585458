import styled from 'styled-components';
import WithDirection from '../../theme/withDirection';

const TopbarWrapper = styled.div`

    .languageSwitch:hover{
      color: #ea5b0b;
    }
    @media only screen and (max-width: 767px) {
    }

    @media only screen and (max-width: 480px) {
    }

    @media only screen and (min-width: 1400px) {

    }



  }
`;

export default WithDirection(TopbarWrapper);
