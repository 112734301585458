import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/languageSwitcher/actions';
import config from './config';
import TopbarWrapper from './topbar.style';
const { changeLanguage } = actions;

class LanguageSwitcher extends Component {
  render() {
    const { language, changeLanguage } = this.props;
 
    const buttonStyle = {
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      marginLeft: '5px',
      marginRight: '5px',
      outline: 'none',
      display: 'block',
      marginBottom: '5px'
    }
    return (	
    <TopbarWrapper>
      <div className="themeSwitchBlock">

        <div className="themeSwitchBtnWrapper">
          {config.options.map(option => {
            const { languageId, locale } = option;
            const customClass =
              languageId === language.languageId
                ? 'selectedTheme languageSwitch'
                : 'languageSwitch';

            return (
              <button
                type="button"
                style={buttonStyle}
                className={customClass}
                key={languageId}
                onClick={() => {
                  changeLanguage(languageId);
                }}
              > 
                {locale.toUpperCase()}
              
              </button>
            );
          })}
        </div>
      </div>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.LanguageSwitcher.toJS(),
  }),
  { changeLanguage }
)(LanguageSwitcher);
