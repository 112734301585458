const actions = {
	ASSET_NEW_REQUEST: 'ASSET_NEW_REQUEST',
	ASSET_GET_REQUEST: 'ASSET_GET_REQUEST',
	ASSET_GET_SUCCESS: 'ASSET_GET_SUCCESS',
	ASSET_GET_ERROR: 'ASSET_GET_ERROR',
	ASSET_SAVE_REQUEST: 'ASSET_SAVE_REQUEST',
	ASSET_SAVE_SUCCESS: 'ASSET_SAVE_SUCCESS',
	ASSET_SAVE_ERROR: 'ASSET_SAVE_ERROR',
	ASSET_CREATE_REQUEST: 'ASSET_CREATE_REQUEST',
	ASSET_CREATE_SUCCESS: 'ASSET_CREATE_SUCCESS',
	ASSET_CREATE_ERROR: 'ASSET_CREATE_ERROR',
	ASSET_DELETE_REQUEST: 'ASSET_DELETE_REQUEST',
	ASSET_DELETE_SUCCESS: 'ASSET_DELETE_SUCCESS',
	ASSET_DELETE_ERROR: 'ASSET_DELETE_ERROR',
	GET_ALL_ASSETS: 'GET_ALL_ASSETS',
	GET_ALL_ASSETS_SUCCESS: 'GET_ALL_ASSETS_SUCCESS',
	GET_ALL_ASSETS_ERROR: 'GET_ALL_ASSETS_ERROR',

	setCurrentStep: step => ({
		type: actions.ASSET_SET_STEP,
		step: step
	}),
	getAsset: id => ({
		type: actions.ASSET_GET_REQUEST,
		id: id
	}),
	getAllAssets: () => ({
		type: actions.GET_ALL_ASSETS,
	}),
	newAsset: () => ({
		type: actions.ASSET_NEW_REQUEST
	}),
	saveAsset: (schoolId, data) => ({
		type: actions.ASSET_SAVE_REQUEST,
		id: schoolId,
		data: data
	}),

	deleteAsset: schoolId => ({
		type: actions.ASSET_DELETE_REQUEST,
		id: schoolId
	}),
	clearNotifications: () => ({
		type: actions.CLEAR_NOTIFICATIONS
	}),

	clearResults: () => ({
		type: actions.CLEAR_RESULTS
	})
};
export default actions;
