import Enlang from './entries/en-US';
import DeLang from './entries/de_DE';

const AppLocale = {
  en: Enlang,
  // de: DeLang
};


export default AppLocale;
