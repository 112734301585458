import bugsnag from "bugsnag-js";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
//import registerServiceWorker from './registerServiceWorker';
import themes from "./theme/themes";
import PicsureApp from "./picsureApp";
import AppLocale from "./languageProvider";
import config, { getCurrentLanguage } from "./containers/LanguageSwitcher/config";
import { themeConfig } from "./picsureAppConfig";
import PicsureAppHolder from "./picsureAppStyle";
import createPlugin from "bugsnag-react";
import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
const analyticsId = process.env.ANALYTICS_ID;
const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];
const isNotLocalEnv = process.env.NODE_ENV !== "local" && process.env.NODE_ENV !== "compose" && process.env.NODE_ENV !== "development";
let ErrorBoundary = null;
let bugsnagClient = null;

if (isNotLocalEnv) {
  bugsnagClient = bugsnag({
    apiKey: process.env.BUGSNAG_KEY,
    releaseStage: process.env.NODE_ENV,
  });
  ErrorBoundary = bugsnagClient.use(createPlugin(React));
}

//init GA
ReactGA.initialize(analyticsId);
console.log("initGA",analyticsId);

ReactDOM.render(
  <ConfigProvider locale={currentAppLocale.antd}>
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ThemeProvider theme={themes[themeConfig.theme]}>
        {isNotLocalEnv ? (
          <ErrorBoundary>
            <PicsureAppHolder>
              <PicsureApp />
              <Toaster
                position="top-center"
                toastOptions={{
                  style: {
                    border: "1px solid #ea5b0b",
                  },
                }}
              />
            </PicsureAppHolder>
          </ErrorBoundary>
        ) : (
          <PicsureAppHolder>
            <PicsureApp />
            <Toaster
              position="top-center"
              toastOptions={{
                style: {
                  border: "1px solid #ea5b0b",
                },
              }}
            />
          </PicsureAppHolder>
        )}
      </ThemeProvider>
    </IntlProvider>
  </ConfigProvider>,
  document.getElementById("root")
);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./picsureApp.js", () => {
    ReactDOM.render(<PicsureApp />, document.getElementById("root"));
  });
}
//registerServiceWorker();
export { AppLocale };
