import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	asset: null,
	loading: true,
	error: false,
	current: 0,
	mainError: false
});


export default function reducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_ALL_ASSETS:
			return {
				...state,
				loading: true,
				assets: null
			};
		case actions.GET_ALL_ASSETS_SUCCESS:
			return {
				...state,
				loading: false,
				assets: action.assets
			};
		case actions.GET_ALL_ASSETS_ERROR:
			return {
				...state,
				loading: false,
				assets: null,
				error: true,
				errorMessage: action.errorMessage
			};
		case actions.ASSET_GET_REQUEST:
			return {
				...state,
				loading: true,
				asset: null
			};

		case actions.ASSET_GET_SUCCESS:
			return {
				...state,
				loading: false,
				asset: action.asset,
				lookupResponse: null,
				error: false,
				errorMessage: null
			};

		case actions.ASSET_GET_ERROR:
			return {
				...state,
				loading: false,
				asset: null,
				error: true,
				errorMessage: action.errorMessage
			};

		case actions.ASSET_SAVE_REQUEST:
			return {
				...state,
				saving: true
			};

		case actions.ASSET_SAVE_SUCCESS:
			return {
				...state,
				saving: false,
				asset: action.asset,
				error: false,
				errorMessage: null
			};

		case actions.ASSET_SAVE_ERROR:
			return {
				...state,
				saving: false,
				error: true,
				errorMessage: action.errorMessage
			};

		case actions.ASSET_DELETE_REQUEST:
			return {
				...state,
				loading: true
			};

		case actions.ASSET_DELETE_SUCCESS:
			return {
				...state,
				loading: false,
				asset: null,
				error: false,
				errorMessage: null,
				success: true,
				message: action.message
			};

		case actions.ASSET_DELETE_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.errorMessage
			};

		case actions.ASSET_NEW_REQUEST:
			return {
				...state,
				asset: {
					name: '',
					target_market: ''
				},
				loading: false,
				error: true,
				errorMessage: action.errorMessage
			};

	
		case actions.CLEAR_NOTIFICATIONS:
			return {
				...state,
				error: false,
				success: false,
				message: false,
				errorMessage: null
			};
		
		case actions.CLEAR_RESULTS:
			return {
				...state,
				lookupWaiting: false,
				lookupResponse: null,
				error: false,
				success: false,
				message: false,
				loading: false,
				errorMessage: null
			};
		case actions.ASSET_SET_STEP:
			return {
				...state,
				current: action.step
			};
		default:
			return state;
	}
}
