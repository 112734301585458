import { Map } from "immutable";
import { push } from "react-router-redux";

export function clearToken() {
  localStorage.removeItem("access_token");
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("access_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}
export async function graphqlQuery(queryString) {
  try {
    const data = await fetch(process.env.GRAPHQL_HOST, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.access_token}`,
      },
      body: queryString,
    });
    return data.json();
  } catch (error) {
    console.error(error);
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}
export async function performRequest(method, url, baseURL, data, auth, customHeader = "") {
  let headers = {};

  if (auth) {
    if (localStorage.access_token) {
      //set Auth-header
      headers = {
        Authorization: `Bearer ${localStorage.access_token}`,
      };
    } else {
      // no token push to signin
      return push("/");
    }
  }

  //add custom header if provided
  if (customHeader) {
    Object.assign(headers, customHeader);
  }

  const response = await fetch(baseURL + url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headers,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: method === "get" ? null : data, // body data type must match "Content-Type" header
  });
  return response.json();
}

export const getRiskLevelEarthquake = (value) => {
  const percentValue = value * 100;
  switch (true) {
    case percentValue < 5:
      return 0;
      break;
    case percentValue < 10:
      return 1;
      break;
    case percentValue < 15:
      return 2;
      break;
    case percentValue < 25:
      return 3;
      break;
    case percentValue < 40:
      return 4;
      break;
    case percentValue < 70:
      return 5;
      break;
    case percentValue < 100:
      return 6;
      break;
  }
};
