import React from 'react';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

class AppRouter extends React.Component {
	render() {
		const { url, history } = this.props;
		return (
			<Switch>
				<Route exact history={history} path={`${url}/dashboard`} component={asyncComponent(() => import('../Dashboard/index.js'))} />
				<Route useAutoKeys={true} path={`${url}/asset/:cod_mod`} component={asyncComponent(() => import('../Asset'))} />
				<Route useAutoKeys={true} path={`${url}/assets`} component={asyncComponent(() => import('../Assets'))} />
				<Route useAutoKeys={true} path={`${url}/cockpit`} component={asyncComponent(() => import('../Cockpit'))} />
				<Route exact path={`${url}/profile`} component={asyncComponent(() => import('../Profile'))} />

			</Switch>
		);
	}
}

export default AppRouter;
