import { push } from "react-router-redux";
import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { performRequest } from "../../helpers/utility";
import actionsAuth from "../auth/actions";
import actions from "./actions";

const DEV = process.env.NODE_ENV !== "production";

//worker
export function* getSchoolAsync(action) {
  DEV && console.log("ACTION.GETSchool", action);
  try {
    DEV && console.log("ID", action.id);
    //Call utility function for request  - authentication flag false because auth not by token
    const responseSchool = yield call(performRequest, "get", "/api/v1/schools/" + action.id, "/proxy", {}, true, {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const schoolData = responseSchool;

    const responseMinedu = yield call(performRequest, "get", "/api/v1/minedu/schools/" + schoolData.id, "/proxy", {}, true, {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const responseLocalsec = yield call(performRequest, "get", "/api/v1/minedu/buildings/" + responseMinedu.localsec, "/proxy", {}, true, {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    });

    schoolData["minedu"] = responseMinedu;
    schoolData["localsec"] = responseLocalsec;
    DEV && console.log("schoolData response", schoolData);

    yield put({
      type: actions.SCHOOL_GET_SUCCESS,
      school: schoolData,
    });
  } catch (error) {
    // if (error.response.status === 401 || error.response.status === 403) {
    // 	DEV && console.log('unauthorized, logging out ...');

    // 	yield put({ type: actionsAuth.REAUTHENTICATE_REQUEST });
    // } else {
    console.log("error", error);
    yield put({
      type: actions.SCHOOL_GET_ERROR,
      errorMessage: "There was an error loading the school",
    });
    // }
  }
}
export function* getAllSchools(action) {
  DEV && console.log("ACTION.GETALLSchool", action);
  try {
    DEV && console.log("ID", action.id);

    // let schoolsData = [];
    // //Call utility function for request  - authentication flag false because auth not by token
    // // const response = yield call(performRequest, 'get', `/customerproject/${action.id}/`, process.env.PICSURE_HOST_AUTH, {}, true);
    // const response = yield call(performRequest, "get", "/api/v1/schools/data/", "/proxy", {}, true, {
    //   accept: "application/json",
    //   "Content-Type": "application/x-www-form-urlencoded",
    // });
    // for (let school of response) {
    //   console.log("foo2", school);
    //   // "http://staging.peru.buildings.picsure.ai/api/v1/minedu/schools/1490739

    //   const responseMinedu = yield call(performRequest, "get", "/api/v1/minedu/schools/" + school._id, "/proxy", {}, true, {
    //     accept: "application/json",
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   });
    //   console.log("foo", responseMinedu);

    //   const responseLocalsec = yield call(performRequest, "get", "/api/v1/minedu/buildings/" + responseMinedu.cod_mod, "/proxy", {}, true, {
    //     accept: "application/json",
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   });
    //   const schoolData = school;

    //   schoolData["minedu"] = responseMinedu;
    //   schoolData["localsec"] = responseLocalsec;

    //   schoolsData.push(schoolData);
    // }
    const schoolsData = null;

    DEV && console.log("schoolsData response", schoolsData);

    yield put({
      type: actions.GET_ALL_SCHOOLS_SUCCESS,
      schools: schoolsData,
    });
  } catch (error) {
    DEV && console.log("login error", error);
    if (error.response.status === 401 || error.response.status === 403) {
      DEV && console.log("unauthorized, logging out ...");

      yield put({ type: actionsAuth.REAUTHENTICATE_REQUEST });
    } else {
      yield put({
        type: actions.GET_ALL_SCHOOLS_ERROR,
        errorMessage: "There was an error loading the school",
      });
    }
  }
}

export function* getSchoolErrorAsync(action) {
  DEV && console.log("ACTION.GETSchoolERROR");
  yield put(push("/app/dashboard"));
}

export function* saveSchoolAsync(action) {
  DEV && console.log("ACTION.saveSchoolPROJECT", action);

  try {
    DEV && console.log("ID", action.id);
    //Call utility function for request  - authentication flag false because auth not by token
    const response = yield call(performRequest, "put", `/customerproject/${action.id}/`, process.env.PICSURE_HOST_AUTH, action.data, true);
    DEV && console.log("saveSchool response", response);

    yield put({
      type: actions.SCHOOL_SAVE_SUCCESS,
      project: response.data,
    });
  } catch (error) {
    DEV && console.log("login error", error);
    if (error.response.status === 401 || error.response.status === 403) {
      DEV && console.log("unauthorized, logging out ...");

      yield put({ type: actionsAuth.REAUTHENTICATE_REQUEST });
    } else {
      yield put({
        type: actions.SCHOOL_SAVE_ERROR,
        errorMessage: "There was an error saving the project",
      });
    }
  }
}

export function* deleteSchoolAsync(action) {
  DEV && console.log("ACTION.deleteSchoolAsync", action);

  try {
    DEV && console.log("ID", action.id);
    //Call utility function for request  - authentication flag false because auth not by token
    const response = yield call(performRequest, "delete", `/customerSchool/${action.id}/`, process.env.PICSURE_HOST_AUTH, {}, true);
    DEV && console.log("deleteSchool response", response);

    yield put({
      type: actions.SCHOOL_DELETE_SUCCESS,
      message: "School deleted successfully",
    });

    //reload data and redirect
    yield put({ type: actionsAuth.REAUTHENTICATE_REQUEST });
    yield put(push("/app/dashboard"));
  } catch (error) {
    DEV && console.log("delete School error", error);
    if (error.response.status === 401 || error.response.status === 403) {
      DEV && console.log("unauthorized, logging out ...");

      yield put({ type: actionsAuth.REAUTHENTICATE_REQUEST });
    } else {
      yield put({
        type: actions.SCHOOL_SAVE_ERROR,
        errorMessage: "There was an error saving the School",
      });
    }
  }
}

//watcher
export function* watchGetSchoolRequest() {
  yield takeLatest("SCHOOL_GET_REQUEST", getSchoolAsync);
}
export function* watchGetAllSchools() {
  yield takeLatest("GET_ALL_SCHOOLS", getAllSchools);
}

export function* watchGetSchoolErrorRequest() {
  yield takeLatest("SCHOOL_GET_ERROR", getSchoolErrorAsync);
}

export function* watchSaveSchoolRequest() {
  yield takeEvery("SCHOOL_SAVE_REQUEST", saveSchoolAsync);
}

export function* watchDeleteSchoolRequest() {
  yield takeEvery("SCHOOL_DELETE_REQUEST", deleteSchoolAsync);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllSchools),
    fork(watchGetSchoolRequest),
    fork(watchGetSchoolErrorRequest),
    fork(watchSaveSchoolRequest),
    fork(watchDeleteSchoolRequest),
  ]);
}
