import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import ReactGA from "react-ga4";
import appActions from "./redux/app/actions";
const { saveBrowserLocation} = appActions;
const RouteNotFound = () => <Redirect to="/500" />;
const RestrictedRoute = ({ component: Component, isLoggedIn, saveBrowserLocation, location, history, ...rest }) => {
  //TODO find more beautiful way to restore location
  if(!isLoggedIn){
    //save browser location to redux store for restore after signin
    saveBrowserLocation(location.pathname)

  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { location: location },
            }}
          />
        )
      }
    />
  );
};
const PublicRoutes = ({ history, isLoggedIn, saveBrowserLocation }) => {
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return (
    <ConnectedRouter history={history}>
      <div>
        <Switch>
          <Route exact path={"/"} component={asyncComponent(() => import("./containers/Page/signin"))} />
          <Route exact path={"/404"} component={asyncComponent(() => import("./containers/Page/404"))} />
          <Route exact path={"/500"} component={asyncComponent(() => import("./containers/Page/500"))} />

          <Route exact path={"/signin"} component={asyncComponent(() => import("./containers/Page/signin"))} />

          <Route exact path={"/showcase-content"} component={asyncComponent(() => import("./containers/Project/showcaseContent"))} />

          {/* <Route
          exact
          path={'/comingSoon'}
          component={asyncComponent(() =>
            import('./containers/Page/comingSoon')
          )}
        /> */}

          <RestrictedRoute history={history} path="/app" component={App} isLoggedIn={isLoggedIn} saveBrowserLocation={saveBrowserLocation} />

          <RouteNotFound />
        </Switch>
      </div>
    </ConnectedRouter>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
    error: state.Auth.error,
    errorMessage: state.Auth.errorMessage,
    location: state.router.location,
  }),
  { saveBrowserLocation }
)(PublicRoutes);
