import { language } from '../../picsureAppConfig.js';

import englishLang from '../../assets/images/flag/uk.svg';
import germanLang from '../../assets/images/flag/germany.svg';

//GET LOCALE FROM LOCALSTORAGE - FALLBACK IS DEFAULTLANGUAGE
let localStorageLanguage = localStorage.getItem('language') || language;

const config = {
  defaultLanguage: localStorageLanguage,
  options: [
    {
      languageId: 'english',
      locale: 'en',
      code: 'en-US',
      text: 'English',
      icon: englishLang,
    },
    // {
    //   languageId: 'german',
    //   locale: 'de',
    //   code: 'de-DE',
    //   text: 'Deutsch',
    //   icon: germanLang,
    // },

  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
